<style lang="scss" scoped>
/deep/ .comp-user-company {
    max-width: 420px;
}
</style>

<template>
    <div>
        <el-dialog title="事业部和仓库管理" custom-class="c-el-dialog comp-user-company" :visible.sync="showDialog" 
            :before-close="_close" :close-on-click-modal="false" :closeOnPressEscape="false" :showClose="false">
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="80px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-alert class="mrgb20" type="warning" :closable="false" title="请配置当前用户可管理的事业部和仓库 (可多选)"></el-alert>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="姓名">
                            <el-input v-model="dialogData.name" placeholder="" size="medium" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="事业部" prop="companyIds">
                            <el-select :ref="refSelectCompany" class="c-pw100" placeholder="请选择事业部(可多选)" size="medium" multiple
                                clearable v-model="dialogData.companyIds" @change="changeCompany">
                                <el-option v-for="ws in allCompany" :key="ws.id" :label="ws.name" :value="ws.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="仓库">
                            <el-select :ref="refSelectWarehouse" class="c-pw100" placeholder="请选择仓库(可多选)" size="medium" multiple
                                clearable v-model="dialogData.warehouseIds" @change="closeWarehouse">
                                <el-option v-for="ws in allWarehouse" :key="ws.id" :label="ws.name" :value="ws.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <!--<el-button size="small" @click="_close()">关闭</el-button>-->
                <el-button size="small" type="primary" @click="_save()">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import * as funInfomation from "@/api/information"
import * as funUser from "@/api/system/user"
export default {
    name: "compUserCompany",
    data() {
        return {
            refSelectCompany: "refUserCompanyToSelectCompany",
            refSelectWarehouse: "refUserCompanyToSelectWarehouse",
            showDialog: false,
            dialogData: {
                id: "",
                name: "",
                companyIds: [],
                warehouseIds: []
            },
            allCompany: [],
            allWarehouse: [],
            formRefName: "refUserToUserCompany",
            formRules: {
                companyIds: [{ required: true, message: '请选择事业部', trigger: ['blur', 'change']}]
            }
        }
    },
    methods: {
        async open(row) {
            await this.getAllCompany()
            this.dialogData.id = row.id
            this.dialogData.name = row.name
            await funUser.getUserCompaniesAndWarehouses({ id: row.id }).then(res => {
                this.dialogData.companyIds = res.companyIds
                this.dialogData.warehouseIds = res.warehouseIds
            })
            await this.getAllWarehouse()
            this.showDialog = true
        },
        changeCompany() {
            this.closeCompany()
            this.dialogData.warehouseIds = []
            this.getAllWarehouse()
        },
        async getAllCompany(){
            window.$common.fullLoading()
            await funInfomation.searchCompany().then(res => {
                window.$common.closeFullLoading()
                this.allCompany = res.items
            })
        },
        async getAllWarehouse() {
            window.$common.fullLoading()
            await funInfomation.searchWarehouse({ companyIds: this.dialogData.companyIds }).then(res => {
                window.$common.closeFullLoading()
                this.allWarehouse = res.items
            })
        },
        _save() {
            this.$refs[this.formRefName].validate(valid => {
                if (valid) {
                    let commitData = {
                        id: this.dialogData.id,
                        companyIds: this.dialogData.companyIds,
                        warehouseIds: this.dialogData.warehouseIds
                    }
                    window.$common.fullLoading()
                    funUser.setUserCompaniesAndWarehouses(commitData).then(() => {
                        this.commonSuccess()
                    })
                } else {
                    return false
                }
            })
        },
        closeCompany() {
            this.$refs[this.refSelectCompany] && this.$refs[this.refSelectCompany].blur()
        },
        closeWarehouse() {
            this.$refs[this.refSelectWarehouse] && this.$refs[this.refSelectWarehouse].blur()
        },
        _close() {
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        },
        commonSuccess(val, data) {
            let tips = val || "操作成功!"
            this.alert(tips).then(() => {
                this._close()
                window.$common.closeFullLoading()
                this.refreshData(data)
            })
        },
        refreshData(data) {
            this.$emit("refreshData", data)
        }
    }
}
</script>