<style lang="scss" scoped>
/deep/ .comp-user-warehourse {
    max-width: 420px;
}
</style>

<template>
    <div>
        <el-dialog title="仓库管理" custom-class="c-el-dialog comp-user-warehourse" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="80px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-alert class="mrgb20" type="warning" :closable="false" title="配置当前用户可管理的仓库 (可多选)"></el-alert>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="姓名">
                            <el-input v-model="dialogData.name" placeholder="" size="medium" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="仓库">
                            <el-select class="c-pw100" placeholder="请选择仓库(可多选)" size="medium" filterable multiple
                                clearable v-model="dialogData.warehouseIds">
                                <el-option v-for="ws in allWarehouse" :key="ws.id" :label="ws.name" :value="ws.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">关闭</el-button>
                <el-button size="small" type="primary" @click="_save()">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import * as funInfomation from "@/api/information"
import * as funUser from "@/api/system/user"
export default {
    name: "compUserWarehourse",
    data() {
        return {
            showDialog: false,
            dialogData: {
                id: "",
                name: "",
                warehouseIds: ""
            },
            allWarehouse: [],
            formRefName: "refUserToUserWarehourse",
            formRules: {
                warehouseIds: [{ required: true, message: '请选择仓库', trigger: ['blur', 'change']}]
            },

        }
    },
    methods: {
        async open(row) {
            await this.getAllWarehouse()
            this.dialogData.id = row.id
            this.dialogData.name = row.name
            funUser.getUserWarehouses({ id: row.id }).then(res => {
                this.dialogData.warehouseIds = res
                this.showDialog = true
            })
        },
        async getAllWarehouse() {
            window.$common.fullLoading()
            await funInfomation.searchWarehouse().then(res => {
                window.$common.closeFullLoading()
                this.allWarehouse = res.items
            })
        },
        _save() {
            this.$refs[this.formRefName].validate(valid => {
                if (valid) {
                    let commitData = {
                        id: this.dialogData.id,
                        warehouseIds: this.dialogData.warehouseIds
                    }
                    window.$common.fullLoading()
                    funUser.setUserWarehouses(commitData).then(() => {
                        this.commonSuccess()
                    })
                } else {
                    return false
                }
            })
        },
        _close() {
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        },
        commonSuccess(val, data) {
            let tips = val || "操作成功!"
            this.alert(tips).then(() => {
                this._close()
                window.$common.closeFullLoading()
                this.refreshData(data)
            })
        },
        refreshData(data) {
            this.$emit("refreshData", data)
        }
    }
}
</script>